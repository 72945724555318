<template>
	<div id="liveList">
		<page-live-preview ref="live" :is-shop="0"></page-live-preview>
	</div>
</template>
<script>
	import PageLivePreview from '@/components/layout/live/page-live-preview.vue'
	export default{
		components:{
			PageLivePreview
		},
		beforeRouteLeave (to, from, next) {
			this.$refs['live'].clearPlayer();
		    from.meta.keepAlive = false;
		    next();
		},
	}
	
</script>

<style>
	
</style>
